<template>
    <section class="anuncios-ver-anuncios-vivienda">
        
        <div class="row mx-0 h-100">
            <div class="col-4 col-lg-4 col-xl-3 py-3 pr-0 scroll-none overflow-auto  border-right">
                <div class="container-left-side row mx-0 justify-content-center">
                    <div class="col">
                        <card-anuncio v-for="(cartelera,i) in carteleras" :key="i" class="mb-3 mx-auto" :cartelera="cartelera" @mostrar="mostrar" />
                        <infinite-loading @infinite="listarPorVivienda">
                          <div slot="spinner">Consultando...</div>
                          <div slot="no-more">No hay más carteleras</div>
                          <div slot="no-results"></div>
                        </infinite-loading>
                    </div>
                </div>
            </div>
            <div class="col-8 col-lg- col-xl-9">
                <div class="row">
                    <div class="col-11 col-sm-11 col-md-12 col-lg-12 col-xl-7">
                        <div class="row mt-4">
                            <div class="col-12">
                                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                                    <el-breadcrumb-item :to="{ name: 'anuncios.main' }">Solicitudes de anuncios</el-breadcrumb-item>
                                    <el-breadcrumb-item :to="{ name: 'anuncios.solicitudes.ver' }">Solicitud</el-breadcrumb-item>
                                    <el-breadcrumb-item>Anuncios de la vivienda</el-breadcrumb-item>
                                </el-breadcrumb>
                            </div>
                            <div class="col-12 mt-3 mb-1">
                                <div v-if="(typeof cartelera.imagenes != 'undefined' && cartelera.imagenes.length > 0 )">
                                <vueper-slides class="vueperslides--1" ref="vueperslides1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })" fixed-height="325px" :arrows="false">
                                    <vueper-slide v-for="(slide, i) in cartelera.imagenes" :key="i" :image="slide.imagen"> </vueper-slide>
                                </vueper-slides>

                                <vueper-slides class="no-shadow vueperslides--2 mt-3" ref="vueperslides2" @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })" :visible-slides="cartelera.imagenes.length" fixed-height="87px" :bullets="false" :touchable="false" :gap="2.5" :arrows="false">
                                    <vueper-slide v-for="(slide, i) in cartelera.imagenes" :key="i" :image="slide.imagen" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)"></vueper-slide>
                                </vueper-slides>
                              </div>
                              <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="obj-cover h-100 w-100">
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-sm-11 col-md-12 col-lg-12 col-xl-5 pr-0">
                        <div class="row mx-0 mt-4">
                            <p class="f-20 col-12 f-500">Información del anuncio</p>
                            <p class="f-15 col-12 mt-3">{{cartelera.titulo}}</p>
                            <p class="f-17 col-12">{{ convertMoney(cartelera.dinero) }}</p>
                            <div class="col-auto my-2">
                                <div class="categoria d-middle br-20 px-2 f-12" :style="`background: ${cartelera.categoria.color}`">
                                    <i class="icon-shop-outline mr-2 op-05" />
                                    <p class="tres-puntos op-05">{{_.get(cartelera.categoria, 'nombre')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 my-2">
                            <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                <p class="d-flex d-middle f-14">
                                    <i class="icon-update-check f-17 mr-2" />
                                    {{_.get(cartelera, 'publicaciones')}} veces publicado
                                </p>
                            </div>
                            <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                <p class="d-flex d-middle f-14">
                                    <i class="icon-denied f-17 mr-2" />
                                    {{_.get(cartelera, 'rechazos')}} Rechazado
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 my-2">
                            <div class="col-12">
                                <div class="d-flex">
                                    <div class="tipo-user rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                                        <i class="icon-account-outline op-05 f-20" />
                                    </div>
                                    <div class="tres-puntos ml-2">
                                        <p class="f-15 f-500">{{_.get(cartelera.usuario, 'nombre')}}</p>
                                        <p class="f-12 tres-puntos">{{ _.get(cartelera, 'vivienda') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 mt-3 mb-2">
                            <div class="col-12">
                                <p class="f-15">{{ _.get(cartelera, 'descripcion') }}</p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-5 mb-3">
                            <div class="col-12 mb-2">
                                <button class="btn btn-aprobar h-32px f-14 w-100" @click="aprobarAnuncio">
                                    <p class="d-flex op-05 d-middle-center">
                                        <i class="icon-confirmado" />
                                        Aprobar
                                    </p>
                                </button>
                            </div>
                            <div class="col-6 pr-1">
                                <button class="btn btn-rechazar h-32px f-14 w-100" @click="rechazarAnuncio">
                                    <p class="d-flex op-05 d-middle-center">
                                        <i class="icon-denied" />
                                        Rechazar
                                    </p>
                                </button>
                            </div>
                            <div class="col-6 pl-1">
                                <button class="btn btn-eliminar h-32px f-14 w-100" @click="eliminarAnuncio">
                                    <p class="d-flex op-05 d-middle-center">
                                        <i class="icon-delete-outline" />
                                        Eliminar
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Partials -->
        <modal-confirmar ref="modalabrirAprobarAnuncio" titulo="Aprobar anuncio" mensaje="¿Desea aprobar este anuncio?" tamano="modal-sm" adicional="Aprobar" />
        <modal-rechazar-anuncio ref="modalabrirRechazarAnuncio" />
        <modal-eliminar ref="modalabrirEliminarAnuncio" titulo="Eliminar anuncio" mensaje="¿Desea eliminar el anuncio?" tamano="modal-md" class="f-15 text-center">
            <p class="text-center w-100"> Desaparecerá del listado de anuncios del residente.</p>
        </modal-eliminar>
        <modal-aprobar-anuncio ref="modalabrirAprobarAnuncio2" />
        
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import Carteleras from '~/services/carteleras'
export default {
    components:{
        modalRechazarAnuncio: () => import('./partials/modalRechazarAnuncio'),
        cardAnuncio: () => import('./components/cardAnuncio'),
        modalAprobarAnuncio: () => import('./partials/modalAprobarAnuncio'),
        InfiniteLoading
    },
    data(){
        return{
            slides: [
                {
                    title: 'Slide #1',
                    content: 'Slide content.'
                },
                {
                    title: 'Slide #2',
                    content: 'Slide content 2.'
                },
            ],
            slider:{
                data: [
                    { image: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                ]
            },
            page: 1,
            carteleras: [],
            cartelera: {}
        }
    },
    created(){
      this.listarPorVivienda()
    },
    methods:{
        aprobarAnuncio(){
            this.$refs.modalabrirAprobarAnuncio2.toggle()
        },
        rechazarAnuncio(){
            this.$refs.modalabrirRechazarAnuncio.toggle()
        },
        eliminarAnuncio(){
            this.$refs.modalabrirEliminarAnuncio.toggle()
        },

        async listarPorVivienda($state){
          try {
            const state = $state
            this.loading = true
            let params= {
              page: this.page,
            }
            const {data} = await Carteleras.listarPorVivienda(this.$route.params.id_vivienda, params)
            
            if(data.data.data.length){
              this.page += 1;
              this.carteleras.push(...data.data.data)
              this.loading = false
              if(typeof state !== 'undefined'){
                state.loaded();
              }
            }else{
              if(typeof state !== 'undefined'){
                state.complete();
              }
              this.loading = false

            }

            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }  
        },
        async mostrar(id){
          try {
            this.loading = true
            
            const {data} = await Carteleras.mostrar(id)
            this.cartelera = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }  
        },
    }
}
</script>
<style lang="scss" scoped>
.anuncios-ver-anuncios-vivienda{
    height: calc(100vh - 55px);
    .vueperslides--2{
        .vueperslide{
            cursor: pointer;
        }
        .vueperslide--active{
            border: 2px solid #109881;
            border-radius: 4px;
        }
    }
    .vueperslide, .vueperslides__parallax-wrapper{
        border-radius: 4px !important;
    }
    .categoria{
        min-height: 22px;
    }
    .tipo-user{
        max-height: 32px;
        min-height: 32px;
        max-width: 32px;
        min-width: 32px;
    }
    .container-left-side{
        // background: #fff 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000014; 
        // border: 1px solid #dbdbdb; 
        height: calc(100vh - 90px); 
        position: relative;
        .icon-pin::before{
            margin-left: 0px;
        }
        .fijar-anuncio{
            color: #00D9B5;
        }
    }
    .btn-{
        &aprobar{
            background: #93EDCF;
        }
        &rechazar{
            background: #FFD54F;
        }
        &eliminar{
            background: #FFB2C2;
        }
    }
}
</style>